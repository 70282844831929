addLoadEvent(function () {
  document.body.style.display = 'block';

  var jQuery = window.jQuery = require('jquery');
  var querySelector = require('query-selector-all');
  var proccessForm = require('./_proccessForm');

  require('fancybox')(jQuery);
  require('jquery-cycle');
  require('./_player');
  require('./_banners');
  require('./_noticias');
  require('./_artigos');

  querySelector('[href="#"]', function (anchor) {
    anchor.addEventListener('click', function (event) {
      event.preventDefault();
    });
  });

  jQuery('.fancybox').fancybox({padding: 0, margin: 30});

  querySelector('.header__nav-toggle', function (toggler) {
    toggler.addEventListener('click', function (event) {
      event.preventDefault();
      jQuery('.header__nav').toggleClass('open');
    });
  });

  var formContato = document.formContato;
  proccessForm(formContato);
});
